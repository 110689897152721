import './icons.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProps } from './iconProps';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IconButtonProps extends IconProps {
  icon: IconProp;
  background?: string;
  onClick?: (e) => void;
}

export const IconButton = ({ color = '#3B5998', background = 'btn-primary', icon, onClick }: IconButtonProps) => (
  <button className={`btn-floating btn-small ${background}`} onClick={onClick}>
    <div className="icon-button-content">
      <FontAwesomeIcon icon={icon} color={color} />
    </div>
  </button>
);
