import React from 'react';
import { HomeImage } from './home.image';
import { HomeAbout } from './home.about';
import { HomeServices } from './home.services';
import { HorizontalRule } from '../horizontalRule';
import { HomeStreak } from './home.streak';
import { Layout } from '../layout';
import Testimonials from './home.testimonials';
import { testimonials } from '../../models/testimonials.model';

export const Home = () => (
  <Layout>
    <HomeImage />
    <HorizontalRule />
    <HomeAbout />
    <HomeStreak />
    <HorizontalRule />
    <HomeServices />
    <HorizontalRule />
    <Testimonials items={testimonials} />
  </Layout>
);
