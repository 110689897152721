import React from 'react';

export const HomeStreak = () => (
  <div className="streak streak-photo hidden-md-down mb-3" style={{ backgroundImage: "url('/img/AdobeStock_105847359.jpg')" }}>
    <div className="flex-center white-text pattern-1">
      <ul>
        <li>
          <h2 className="h2-responsive ml-3 mr-3">Personal Training Programmes for everybody.</h2>
        </li>
      </ul>
    </div>
  </div>
);
