import React from 'react';

interface HomeSectionProps {
  image?: string;
  imageAlt: string;
  subHeading: string;
  link?: string;
  videoLink?: string;
  buttonText?: string;
  openLinkInNewTab?: boolean;
  children: React.ReactNode;
}

export const HomeSection = ({
  image,
  imageAlt,
  subHeading,
  link,
  videoLink,
  buttonText = 'Find out more...',
  openLinkInNewTab,
  children
}: HomeSectionProps) => (
  <React.Fragment>
    <div className="row">
      <div className="col-md-12">
        <div>
          <div className="service-section mb-0" id="gym" role="tabpanel" aria-expanded="true">
            <br />
            <div className="row">
              <div className="col-lg-6 col-md-12 mb-2">
                {videoLink ? (
                  <iframe
                    style={{ height: '100%', width: '100%', minHeight: '250px' }}
                    src={videoLink}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={false}
                  />
                ) : (
                  <div className="view overlay hm-white-slight z-depth-2">
                    <img src={`/img/${image}`} alt={imageAlt} />
                  </div>
                )}
              </div>

              <div className="col-lg-6 col-md-12 text-left">
                <h2 className="mb-1">{subHeading}</h2>

                {children}
                {link && (
                  <a href={link} className="btn btn-primary waves-effect" target={openLinkInNewTab ? '_blank' : ''}>
                    {buttonText}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);
