export interface Testimonial {
  name: string;
  source: string;
  link: string;
  comment: string;
}

const googleReviewLink = 'https://business.google.com/b/113989598534584495746/reviews/l/17744649974017623217?hl=en';

export const testimonials: Testimonial[] = [
  {
    name: 'Lisa Montgomery',
    source: 'Google',
    link: googleReviewLink,
    comment:
      "Sarah is a fabulous PT. She's very good at motivating and giving great advice about the correct positions to use when doing weights and how to strengthen my core. I've lost weight with Sarah and really enjoyed the sessions. We have a good giggle as well. Would definitely recommend this great one to one service."
  },
  {
    name: 'Lynne Shimell',
    source: 'Google',
    link: googleReviewLink,
    comment:
      "Sarah is great, I'm really enjoying the sessions and getting so much out if them .She's full of energy,advise and motivation and is really putting my heath back on track .Shes so much more than a P T ."
  },
  {
    name: 'Kev Sutton',
    source: 'Google',
    link: googleReviewLink,
    comment:
      'My son Ally went to Sarah for some one 2 one sessions before he started his pre season training with AFC Fylde. He loved going and Sarah worked on his fitness, stamina and core strength through a variety of different exercises... We highly recommend going to Sarah at Fit Bodiz as she offers a friendly and very professional service covering all aspects of fitness.'
  },
  {
    name: 'Janice Smith',
    source: 'Google',
    link: googleReviewLink,
    comment:
      'Really enjoying my sessions with Sarah! Loving how she pushes me and doesn’t let me give up! I can really feel the difference with my fitness and stamina and I know when I need any fitness advice she’s at the end of the phone! I would definitely recommend!! Fab price too for excellent service.'
  },
  {
    name: 'Kerry-Ann Walsh',
    source: 'Google',
    link: googleReviewLink,
    comment:
      "Just love my weekly session with Sarah. I've been going a month now and have lost weight, lost fat and increased muscle. She is so motivating and really pushes me to my limits whilst making it fun. Would highly recommend to anyone."
  },
  {
    name: 'Yvonne Robinson',
    source: 'Google',
    link: googleReviewLink,
    comment:
      "Sarah is a brilliant personal trainer. She is really professional and understands what you want to achieve. I'd highly recommend if you are looking for classes or a PT"
  },
  {
    name: 'Shirley Knox',
    source: 'Google',
    link: googleReviewLink,
    comment:
      'I have been with Fitbodiz for a couple of months now. I am truly enjoying it. Sarah is an excellent trainer and allows me to train at my own pace, and giving me a gentle push when needed. I like the idea of being able to pay as you go, which is great when money can sometimes be tight, and not being tied up to an expensive yearly membership.'
  },
  {
    name: 'Kerry Edwards',
    source: 'Google',
    link: googleReviewLink,
    comment:
      'Fantastic, professional service! Sarah helps you get the most out of every session and really keeps you motivated.Can highly recommend!'
  },
  {
    name: 'Kerry Wilcock',
    source: 'Google',
    link: googleReviewLink,
    comment:
      "I've been to fitbodiz circuit class a couple of times now and have loved every minute! Sarah is great at motivating you,she really takes the time to help you get the best out of the class.I would reccomend Fitbodiz to anyone,best in the area!"
  },
  {
    name: 'Amy Sudell',
    source: 'Facebook',
    link: 'https://www.facebook.com/pg/fitbodizfitness/reviews',
    comment:
      'Sarah works hard to help us get the most out of the sessions. She is very encouraging and the classes are always fun! Thank you!'
  },
  {
    name: 'Sarah Ball',
    source: 'Google',
    link: googleReviewLink,
    comment:
      'Love the fitness classes, Sarah keeps me motivated and has helped me get back into fitness after an operation on my toe, taking it slow and steady. Great range of full body workouts and always come away feeling I have worked hard!'
  },
  {
    name: 'Jonathan Yates',
    source: 'Google',
    link: 'https://business.google.com/b/113989598534584495746/reviews/l/17744649974017623217?hl=en',
    comment: 'Very good fitness classes. Sarah really knows her stuff and is a great motivator. Fit Bodiz has really helped me.'
  }
];
