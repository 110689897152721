import React from 'react';

export const HomeImage = () => (
  <section>
    <div className="view hm-black-medium z-depth-2">
      <img
        src="/img/carousel/AdobeStock_10608739.jpg"
        className="img-fluid rounded hidden-sm-down"
        alt="Fitbodiz Fitness Classes"
      />
      <img src="/img/AdobeStock_10608739.jpg" className="img-fluid rounded hidden-md-up" alt="Fitbodiz Fitness Classes" />

      <div className="full-bg-img white-text">
        <div className="flex-column flex-center mr-2 ml-2 text-center">
          <h1 className="display-4 hidden-lg-down">PROFESSIONAL PERSONAL TRAINING</h1>
          <h1 className="hidden-xl-up hidden-md-down">PROFESSIONAL PERSONAL TRAINING</h1>
          <h1 className="h1-responsive hidden-lg-up hidden-xs-down">PROFESSIONAL PERSONAL TRAINING</h1>
          <div className="h5-responsive hidden-sm-up" style={{ fontWeight: 300 }}>
            PROFESSIONAL PERSONAL TRAINING
          </div>

          <h2 className="h2-responsive hidden-sm-down">With a Fully Qualified Personal Trainer</h2>
          <h4 className="h4-responsive hidden-sm-down">A great way to get fit, lose weight, get toned and have fun.</h4>

          <h6 className="h6-responsive hidden-md-up">With a Fully Qualified Personal Trainer</h6>
          <h6 className="h6-responsive hidden-xs-down hidden-md-up">Get fit, lose weight, get toned and have fun</h6>

          <div className=" mt-1 mb-0 hidden-xs-down d-flex">
            <a href="./about-fitbodiz" className="btn btn-primary waves-effect">
              Find out more...
            </a>
          </div>

          <div className="mt-0 mb-1 hidden-sm-up d-flex">
            <a href="./about-fitbodiz" className="btn btn btn-primary waves-effect">
              Find out more...
            </a>
          </div>

          <h2 className="h1-responsive mt-2 hidden-xs-down">Heathly Bodiz - Strong Bodiz - Fit Bodiz</h2>
          <div className="h6-responsive hidden-sm-up" style={{ fontWeight: 300 }}>
            Heathly Bodiz - Strong Bodiz - Fit Bodiz
          </div>
        </div>
      </div>
    </div>
  </section>
);
