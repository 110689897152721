import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faStar, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { Testimonial } from '../testimonial.model';
import { IconButton } from '../icons/IconButton';

interface TestimonialsProps {
  items: Testimonial[];
}

const Testimonials = ({ items }: TestimonialsProps) => {
  const [state, setState] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      nextItems();
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  });

  const previousItems = () => {
    const previous = state === 0 ? 0 : state - 1;
    setState(previous);
  };

  const nextItems = () => {
    const next = state * 3 + 3 >= items.length ? 0 : state + 1;
    setState(next);
  };

  const from = state * 3;
  const to = from + 3;
  const count = Math.round(items.length / 3);
  const indicators = Array.from(Array(count).keys());

  return (
    <section className="section text-center mt-4 col-12">
      <h1 className="h1 mb-1">Testimonials</h1>

      <div className="carousel testimonial-carousel carousel-multi-item">
        <div>
          <IconButton icon={faChevronLeft} color="white" onClick={previousItems} />
          <IconButton icon={faChevronRight} color="white" onClick={nextItems} />
        </div>

        <div className="container">
          <div className="row">
            {items.slice(from, to).map((item, i) => (
              <div key={i} className="col-md-12 col-lg-4">
                <div className="testimonial mt-2">
                  <h4>{item.name}</h4>
                  <a href={item.link} target="_blank">
                    <h6 className="blue-text font-bold">{item.source} review</h6>
                  </a>
                  <p>
                    <FontAwesomeIcon icon={faQuoteLeft} color="grey" />
                    <span className="ml-1 mr-1">{item.comment}</span>
                    <FontAwesomeIcon icon={faQuoteRight} color="grey" />
                  </p>
                  <div className="grey-text">
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <ol className="carousel-indicators">
          {indicators.map((_, i) => (
            <li key={i} className={`${i === state && 'active '}light-blue darken-4`} />
          ))}
        </ol>
      </div>
    </section>
  );
};

export default Testimonials;
