import React from 'react';
import { HorizontalRule } from '../horizontalRule';

export const HomeAbout = () => (
  <section id="about" className="section about">
    <div className="row">
      <div className="col-lg-6 col-md-12 hidden-md-down">
        <img src="/img/AdobeStock_76490001.jpg" className="img-fluid" alt="About Fit Bodiz" />
      </div>

      <div className="col-lg-6 col-md-12">
        <h2 className="grey-text-2">Welcome to Fit Bodiz</h2>

        <p>
          Hi, my name is Sarah Bickford-Yates and I run Fit Bodiz. I am a Fully Qualified Personal Trainer based in Garstang
          Lancashire. Fit Bodiz offers unique one to one Personal Training Programmes to suite everybody.
        </p>
        <p className="hidden-lg-down">
          I want to share the great feeling of exercise with you and want people to come to Fit Bodiz to get fit, toned, lose
          weight, be motivated and feel healthier. I will fit in around your busy life styles and budget.
        </p>
        <p className="hidden-lg-up">
          I love every form of exercise from running to weight training. I want to share the great feeling of exercise with you
          and want people to come to Fit Bodiz to get fit, toned, lose weight, be motivated and feel healthier. I will fit in
          around your busy life styles and budget. The feeling you get from doing exercise is like no other, it puts a smile on
          your face, an ache in your muscles and a body you want to show to the world every single day.
        </p>

        <p>Come and join me and let’s get Fit Bodiz together...</p>

        <a href="./about-fitbodiz" className="btn btn-primary waves-effect">
          Find out more...
        </a>
      </div>
    </div>
    <HorizontalRule />
  </section>
);
