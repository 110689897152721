import React from 'react';
import { HomeSection } from './home.section';
import { Heading } from './heading';
import { HorizontalRule } from '../horizontalRule';

export const HomeServices = () => (
  <section className="section">
    <Heading text="Fitness Training Programmes" />
    <HomeSection
      image="AdobeStock_123802465.jpg"
      imageAlt="Personal Trainer"
      subHeading="Personal Training"
      link="./personal-trainer"
    >
      <p>As a Fully Qualified Personal Trainer I offer one-to-one and group Personal Training Programmes to suite everybody.</p>
      <p>
        Whether you want to lose weight or get fit I will help you reach your goals and push you to the best of your ability...
      </p>
    </HomeSection>
    <HorizontalRule />

    <Heading text="Nutrition and Weight Management" />
    <HomeSection
      image="AdobeStock_74438886.jpg"
      imageAlt="Nutrition and Weight Management"
      link="./blog/nutrition-qualification/"
      subHeading=""
    >
      <p>
        {' '}
        I am fully qualified in{' '}
        <b>
          <a
            href="https://www.futurefit.co.uk/fitness-courses/nutrition-courses/nutrition-cpd-courses/nutrition-and-weight-management/"
            target="_blank"
          >
            Nutrition & Weight Management.
          </a>
        </b>{' '}
        This enables me to provide you with dietary advice and deliver professional weight management as an essential addition to
        my personal training service.
      </p>
      <p>
        I am certified by The Association for Nutrition at Level 4 of the Health & Social Care Competency Framework. I have
        extensive understanding of the principles of nutrition and weight management and the science that underpins it.
      </p>
    </HomeSection>
    <HorizontalRule />

    <Heading text="Sarah's Blog" />
    <HomeSection image="AdobeStock_79367708.jpg" imageAlt="Blog" link="./blog" buttonText="Go to blog..." subHeading="">
      <p>Checkout my Blog where you will find helpful and interesting articles to help you with your fitness journey.</p>
      <p>My latest blog post takes a look at why weight training is the way to go.</p>

      <h2 className="mb-1">Weights are the way to go!!</h2>

      <p>
        Don’t underestimate the power of weights! You may be under the impression that to lose weight you need to do lots of
        cardio exercises that make you sweat and get out of breath or you won’t lose weight. You may be under the impression that
        lifting…
      </p>
    </HomeSection>
    <HorizontalRule />

    <Heading text="Pre and Post Natal Exercise and Nutrition" />
    <HomeSection
      image="PreAndPostNatal.jpg"
      imageAlt="Pre and Post Natal Exercise and Nutrition"
      link="./pre-post-natal-exercise-nutrition/"
      subHeading=""
    >
      <p>I'm excited to announce that I am now fully qualified in Pre and Post Natal Exercise and Nutrition.</p>
      <p>
        If you are trying for a baby, pregnant or post natal get in touch. Exercise is an amazing way to keep fit before, during
        and after birth. Helps with your birth and recovery, mentally and physically.
      </p>
    </HomeSection>
    <HorizontalRule />

    <Heading text="Fitness Videos" />
    <HomeSection
      image="IMG_0121.jpg"
      imageAlt="Fitness videos"
      subHeading="Fit Bodiz Fitness Videos"
      link="https://www.youtube.com/channel/UCviMBl8-ye-aPXwiAHGM2lA"
      videoLink="https://www.youtube.com/embed/orXoXoMC_sU"
      buttonText="Vist YouTube Channel"
      openLinkInNewTab={true}
    >
      <p>
        Checkout
        <a href="https://www.youtube.com/channel/UCviMBl8-ye-aPXwiAHGM2lA" target="_blank">
          <b> The Fit Bodiz Fitness Videos Channel </b>
        </a>
        on youtube and lets get fitbodiz together.
      </p>
    </HomeSection>
  </section>
);
